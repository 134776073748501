import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent } from 'app/views/settings/components';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadImageService {
  constructor(public dialog: MatDialog) {}

  openCropperDialog() {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      data: {},
      maxHeight: '90vh',
      panelClass: 'app-image-cropper-dialog',
    });

    return dialogRef.afterClosed().pipe(
      map((result) => {
        if (result == null || result.image === '') {
          return null;
        }

        const block = result.image.split(';');
        // Get the content type of the image
        const contentType = block[0].split(':')[1]; // In this case "image/gif"
        // get the real base64 content of the file
        const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        const blob = this.b64toBlob(realData, contentType);

        const imageName = result.imageName;
        const type = 'image/' + imageName.split('.').pop();

        return new File([blob], imageName, {
          type: type,
        });
      }),
    );
  }

  private b64toBlob(b64Data, contentType, sliceSize = undefined): any {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
